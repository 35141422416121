import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, where, doc, updateDoc, deleteDoc, getDocs, orderBy } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage"
import { db } from '../firebase_config';
import { useNavigate } from 'react-router-dom';
import '../css/ContentListCss.css'
import Swal from "sweetalert2";

import Search from '../images/renewal/Search.svg';
import Refresh from '../images/renewal/Refresh.svg';
import Upload from '../images/renewal/Upload.svg';
import Sort from '../images/renewal/Sort.svg';
import Subtract from '../images/renewal/Subtract.svg';
import Delete from '../images/renewal/Delete.svg';
import CategorySel from '../images/renewal/Category_select.svg';


function ContentList (props) {
    const userAuth = props.userAuth;
    const groupid = props.userGroup;
    const cateName = props.cateVal;
    const repcateName = props.repcateVal;
    const sysname = props.sysDiv;
    const onCategoryUpdate = props.onCategoryUpdate;

    const navigate = useNavigate();

    const [contents, setContents] = useState([]);
    const [selCategoryMenu, setSelCategoryMenu] = useState(false);  //상단 기타 메뉴
    const [categoryList, setCategoryList] = useState([]); //사이드 메뉴
    // const [loadedContentCount, setLoadedContentCount] = useState(9); // 초기값은 9으로 설정
    const [searchTerm, setSearchTerm] = useState('');
    const [contentType, setContentType] = useState("all");
    
    const [refresh, setRefresh] = useState(false);

    const [selectedBtn, setSelectedBtn] = useState("All"); //이미지 비디오 필터 버튼
    const [sortBy, setSortBy] = useState("upload");
    const [isLoading, setIsLoading] = useState(false);

    const callDb = useCallback(async () => {
        setIsLoading(true); // 로딩 시작
        let q;
        const baseQuery = collection(db, "contents");
        const orderByClause = orderBy("uploadtime", "desc");
    
        if (repcateName === "비용안내") {
            q = query(baseQuery, where("repcateval", "==", repcateName), where("groupid", "==", groupid), orderByClause);
        } else if (sysname === "DC") {
            q = query(baseQuery, where("repcateval", "==", repcateName), where("sysdiv", "==", "DC"), orderByClause);
        } else {
            q = query(baseQuery, where("repcateval", "==", repcateName), where("sysdiv", "==", "CA"), where("groupid", "==", groupid), orderByClause);
        }
    
        const snapshot = await getDocs(q);
        const filteredContents = snapshot.docs
            .map(doc => doc.data())
            .filter(content => content.tag.includes(searchTerm));
    
        setContents(filteredContents);
        setTimeout(() => {
            setIsLoading(false); // 2초 후 로딩 종료
        }, 1000);
    }, [repcateName, groupid, searchTerm, sysname]);
    
    useEffect(() => {
        callDb();
    }, [callDb, refresh]);
    
    useEffect(() => {
        const fetchData = async () => {
            const baseQuery = collection(db, "category");
            const orderByClause = orderBy("value");
            let q;
    
            if (sysname === "DC") {
                q = query(baseQuery, where("sysdiv", "==", "DC"), orderByClause);
            } else if (sysname === "CA") {
                q = query(baseQuery, where("sysdiv", "==", "CA"), where("groupid", "==", groupid), orderByClause);
            } else {
                return; // 적절한 sysname이 없으면 함수 종료
            }
    
            const snapshot = await getDocs(q);
            const data = snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
    
            setCategoryList(data);
        };
    
        fetchData();
    }, [groupid, sysname]);

    // const loadMore = () => {
    //     const newLoadedContentCount = loadedContentCount + 9; // 기존 데이터 수에 10을 더함
    //     setLoadedContentCount(newLoadedContentCount); // 상태 변수 업데이트
    
    //     // 추가 데이터 가져오기
    //     // 기존의 쿼리에서 limit을 새로운 데이터 수로 업데이트하여 다시 조회합니다.
    //     const newQ = query(
    //         collection(db, "contents"),
    //         where("repcateval", "==", repcateName),
    //         orderBy("uploadtime", "desc"),
    //         limit(newLoadedContentCount) // 새로운 데이터 수로 limit을 설정
    //     );
    
    //     getDocs(newQ)
    //         .then((snapshot) => {
    //             const newContents = snapshot.docs.map((doc) => doc.data());
    //             setContents(newContents); // 새로운 데이터로 기존 데이터를 대체합니다.
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching documents: ", error);
    //         });
    // };
    
    const toUploadPage = () => {
        if(sysname === "DC"){
            // 비용안내는 다른 치과에서도 독자페이지로 사용할 수 있게 처리 24.02.26
            if(userAuth === 1 || userAuth === 7 || (userAuth === 2 && repcateName === "비용안내")){
                navigate("/contentUpload", {
                    state: {
                        cateval: cateName,
                        sysname: sysname,
                        data: "NEW",
                    }
                });
            }else{
                noAuthAlert();
            }
        }else if(sysname === "CA"){
            if(userAuth === 1 || userAuth === 2 || userAuth === 7){
                navigate("/contentUpload", {
                    state: {
                        cateval: cateName,
                        sysname: sysname,
                        data: "NEW",
                    }
                });
            }else{
                noAuthAlert();
            }
        }
    }

    const noAuthAlert = () => {
        Swal.fire({
          icon: "warning",
          title: "권한 없음",
          text: "해당 메뉴의 권한이 없습니다",
          //showCancelButton: true,
          confirmButtonText: "확인",
          //cancelButtonText: "취소",
        })
    }

    //매개변수를 넘기면서 이미지 수정 페이지로 이동
    const toCttModPage = (props) => {
        // navigate("/contentMod", {
        //     state: {
        //         code: props,
        //         cateval: repcateName,
        //     },
        // });
        navigate("/contentUpload", {
            state: {
                cateval: cateName,
                sysname: sysname,
                data: props,
            }
        });
    }

    //매개변수를 넘기면서 그림판 페이지로 이동
    const toDrawPage = (docData) => {
        navigate("/drawPage", {
            state: {
                fileinfo: docData,
                from: "content",
            },
        });
    }

    //매개변수를 넘기면서 동영상 페이지로 이동
    const toViewVideo = (docData) => {
        navigate("/viewVideo", {
            state: {
                fileinfo: docData,
                from: "content",
            },
        });
    }

    const handleImgVideoClick = async (menu) => {
        setSelectedBtn(menu);

        if(menu === 'All'){
            setContentType("all");
        }else if(menu === 'Img'){
            setContentType("image");
        }else if(menu === 'Video'){
            setContentType("video");
        }
    };

    const handleSelectChange = (e) => {
        setSortBy(e.target.value);
    };

    const handleListItemMod = (props) => {
        if(sysname === "DC"){
            if(userAuth === 1 || userAuth === 7 || (userAuth === 2 && repcateName === "비용안내")){
                toCttModPage(props)
            }else{
                noAuthAlert();
            }
        }else if(sysname === "CA"){
            if(userAuth === 1 || userAuth === 2 || userAuth === 7){
                toCttModPage(props)
            }else{
                noAuthAlert();
            }
        }  
    };

    const handelListItemDel = (props) => {
        // 1, 7은 다 가능하고 2는 비용안내(DentalExplan 하위항목이라), CA메뉴인 경우에만 데이터 조작가능
        if(userAuth === 1 || userAuth === 7 || (userAuth === 2 && sysname === "DC" && repcateName === "비용안내") ||
            (userAuth === 2 && sysname === "CA")){
            Swal.fire({
                icon: "question",
                title: "컨텐츠 삭제",
                text: "컨텐츠 삭제를 하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "삭제",
                cancelButtonText: "취소",
            }).then((res) => {
                if (res.isConfirmed) {
                    deleteDoc(doc(db, "contents", props.fileid))

                    const storage = getStorage();
                    const delRef = ref(storage, props.imgUrl);

                    if(props.filetype === 'image'){
                        deleteObject(ref(storage, props.imgThUrl))
                        .catch((error)=>{
                            console.log(error)
                        })
                    }

                    deleteObject(delRef).then(()=>{
                        Swal.fire({
                            icon: "success",
                            title: "컨텐츠 삭제",
                            text: "컨텐츠 삭제가 완료되었습니다!",
                            //showCancelButton: true,
                            confirmButtonText: "확인",
                        }).then((res) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (res.isConfirmed) {
                                setRefresh(!refresh);
                            }
                        });
                    }).catch((error)=>{
                        console.log(error)
                    })
                }
            })
        }else{
            noAuthAlert();
        }
    }

    const handleCategoryMenuClick = () => {
        setSelCategoryMenu(!selCategoryMenu);
    };

    // 상단 기타메뉴 클릭 이벤트 핸들러 (배경을 클릭하여 메뉴를 닫는 기능)
    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            setSelCategoryMenu(false); // 배경을 클릭한 경우에만 메뉴를 닫습니다.
        }
    };

    const handleCategoryClick = (category) => {
        if(category.sysdiv === "DC" || category.sysdiv === "CA"){
            onCategoryUpdate(category);
            handleCategoryMenuClick();
        }
        // else if(category.sysdiv === "EVA"){
        //   if(menu === 0){
        //     navigate("/evaSignup");
        //   }else if(menu === 1){
        //     navigate("/evaManageFront", { state: { usergroup : userGroup } });
        //   }else if(menu === 2){
        //     navigate("/evaSurveyFront",{
        //       state: {
        //           code: "Eva",
        //       },
        //     });
        //   }else if(menu === 3){
        //     navigate("/evaQuestionManage");
        //   }else if(menu === 4){
        //     navigate("/evaEmployeeManage");
        //   }else if(menu === 5){
        //     navigate("/evaReportHome");
        //   }
        // }else if(category.sysdiv === "ADMIN"){
        //   if(menu === 0){
        //     navigate("/adminMemberlist");
        //   }else if(menu === 1){
        //     navigate("/adminMemAprv");
        //   }else if(menu === 2){
        //     navigate("/adminCommon");
        //   }else if(menu === 3){
        //     navigate("/evaReportAdmin");
        //   }else if(menu === 4){
        //     navigate("/evaEmpMngAdm");
        //   }else if(menu === 5){
        //     navigate("/adminDataMod");
        //   }
        // }
    };

    const toCateModPage = () => {
        navigate("/categoryMod");
    }
    
    return (
        <>
            {isLoading ? (
                <div /> // 또는 로딩 스피너 컴포넌트
            ) : (
                <div className="renew_background contentlist" >
                    <div className="content_align">
                        <div className="content_maxwidth">
                            <div className="contentlist_header_area">
                                <div className="contentlist_header_title_button_area">
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <div className="category_name">{cateName}</div>
                                        {cateName !== '비용안내' && (
                                            <>
                                                <img 
                                                    src={CategorySel} 
                                                    alt="카테고리" 
                                                    className="category_select" 
                                                    onClick={handleCategoryMenuClick}
                                                />
                                                {selCategoryMenu && (
                                                    <>
                                                        <div 
                                                            className="renew_overlay" 
                                                            onClick={() => setSelCategoryMenu(false)}
                                                        />
                                                        <div 
                                                            className="menu_category" 
                                                            onClick={handleBackgroundClick}
                                                        >
                                                            {categoryList
                                                                .filter(option => 
                                                                    option.repcateval !== '기본카테고리' && 
                                                                    option.repcateval !== '비용안내'
                                                                )
                                                                .map((option) => (
                                                                    <div 
                                                                        key={option.id} 
                                                                        style={{marginBottom:"1.25rem"}}
                                                                        onClick={() => handleCategoryClick(option)}
                                                                    >
                                                                        {option.cateval}
                                                                    </div>
                                                                ))
                                                            }
                                                            {sysname === "CA" && 
                                                                <div style={{marginBottom:"1.25rem",cursor:'pointer'}}
                                                                    onClick={()=>{
                                                                    toCateModPage();
                                                                }}>
                                                                    카테고리 편집
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <div className="renew_rectangle_btn" style={{marginLeft:"1.5rem",marginRight:"0.5rem"}} onClick={()=>{ setRefresh(!refresh);}}>
                                            <img src={Refresh} alt="새로고침" style={{width:18,height:18}} />
                                        </div>
                                        <div className="renew_rectangle_btn" onClick={toUploadPage}>
                                            <img src={Upload} alt="업로드" style={{width:18,height:18}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="contentlist_header_searchbox_area">
                                    <div className="renew_contentlist_searchbox">
                                        <input
                                            type="search"
                                            placeholder="검색"
                                            value={searchTerm}
                                            onChange={e => setSearchTerm(e.target.value)}
                                            style={{color:'#191919',fontSize:"0.875rem",fontWeight:500}}
                                            onFocus={() => document.querySelector('.renew_contentlist_searchbox').style.border = '1px solid #E6CE9F'}
                                            onBlur={() => document.querySelector('.renew_contentlist_searchbox').style.border = '1px solid #F3ECE0'}
                                        />
                                        <img src={Search} alt="검색" style={{width:"1.04rem",height:"1.04rem"}} />
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:"1.5rem"}}>
                                <div style={{display:'flex'}}>
                                    <div className="renew_imgvideo_btn"
                                        style={{ color:selectedBtn === 'All' && '#FFF',
                                                backgroundColor:selectedBtn === 'All' && '#191919'}}
                                        onClick={() => handleImgVideoClick('All')}
                                    >전체</div>
                                    <div className="renew_imgvideo_btn"
                                        style={{ color:selectedBtn === 'Img' && '#FFF',
                                                backgroundColor:selectedBtn === 'Img' && '#191919'}}
                                        onClick={() => handleImgVideoClick('Img')}
                                    >이미지</div>
                                    <div className="renew_imgvideo_btn"
                                        style={{ color:selectedBtn === 'Video' && '#FFF',
                                                backgroundColor:selectedBtn === 'Video' && '#191919'}}
                                        onClick={() => handleImgVideoClick('Video')}
                                    >비디오</div>
                                </div>
                                <div style={{display:'inline-flex',alignItems:'flex-start',gap:3,}}>
                                    <select style={{fontSize:"0.9375rem",fontWeight:600,color:'#858585',}} onChange={handleSelectChange}>
                                        <option value="upload">업로드 순</option>
                                        <option value="tag">제목 순</option>
                                        <option value="count">조회 순</option>
                                    </select>
                                    <img src={Sort} alt="정렬" style={{width:18,height:18}} />
                                </div>
                            </div>
                            <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}>
                                {contents
                                    .filter((doc) => {
                                        if (contentType === "all") return true;
                                        return doc.filetype === contentType;
                                    })
                                    .sort((a, b) => {
                                        if (sortBy === "count") {
                                            return b.count - a.count;
                                        } else if (sortBy === "tag") {
                                            return a.tag.localeCompare(b.tag);
                                        } else {
                                            return b.uploadtime - a.uploadtime;
                                        }
                                    })
                                    .map((docData, index) => {
                                        return(
                                            <div key={index} className="contents_unit">
                                                {docData.filetype === "image" ?
                                                    <div className="renew_list_item_thumbnail"
                                                        style={{
                                                                backgroundImage: `url(${docData.thumbnail})`,
                                                                backgroundSize: "cover",
                                                            }}
                                                        onClick={async ()=>{
                                                                            const contentRef = doc(db, "contents", docData.fileid);
                                                                            await updateDoc(contentRef, {
                                                                                count: ++docData.count
                                                                            })
                                                                            toDrawPage(docData);
                                                                }}
                                                    />
                                                :
                                                    <div className="renew_list_item_thumbnail" style={{overflow:'hidden'}}>
                                                        <video src={docData.fileAddr} style={{width:'100%',height:'100%',objectFit:'cover'}}
                                                            onClick={
                                                                async ()=>{
                                                                    const contentRef = doc(db, "contents", docData.fileid);
                                                                    await updateDoc(contentRef, {
                                                                        count: ++docData.count
                                                                    })
                                                                    toViewVideo(docData)
                                                                }
                                                            }
                                                        />   
                                                    </div>
                                                }
                                                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:"1.25rem"}}>
                                                    <div style={{color:'#8D7A5D',fontSize:"1.25rem",fontWeight:500}}>{docData.tag}</div>
                                                    <div style={{display:'flex'}}>
                                                        <div style={{cursor:'pointer'}}
                                                            onClick={()=>handleListItemMod(docData)}
                                                        >
                                                            <img src={Subtract} alt="수정" style={{width:24,height:24,marginRight:14}} />
                                                        </div>
                                                        <div style={{cursor:'pointer'}}
                                                            onClick={()=>handelListItemDel(docData)}
                                                        >
                                                            <img src={Delete} alt="삭제" style={{width:24,height:24}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* 일단 더보기 기능은 쓰지 않는걸로 */}
                            {/* <button onClick={loadMore}>더보기</button> */}
                            <div className="renew_copyright">Copyright 2022. Dental Connect All rights reserved</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ContentList