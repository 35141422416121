import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { getDocs, deleteDoc, doc, where, collection, query, orderBy } from "firebase/firestore";
import Swal from "sweetalert2";

import More from '../images/renewal/More.svg';

function EvaManageFront(props) {
    const userAuth = props.userAuth;
    const navigate = useNavigate();
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const userGroup = state.usergroup;

    const [todayText, setTodayText] = useState("");
    const [patientList, setPatientList] = useState([]);

    const [activeMenu, setActiveMenu] = useState(null)
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

    const callDb = useCallback(async () => {
        let now = new Date();
        const todayYear = now.getFullYear();
        const todayMonth = String(now.getMonth() + 1).padStart(2, '0');
        const todayDate = String(now.getDate()).padStart(2, '0');
        const registerDate = `${todayYear}${todayMonth}${todayDate}`;
    
        setTodayText(String(todayYear) + "년 " + String(todayMonth) + "월 " + String(todayDate) + "일");
    
        const patientRef = collection(db, "evapatient");
        const q = query(patientRef, where("regdate", "==", registerDate),
            where("groupid", "==", userGroup),
            where("surveyYn", "==", "N"),
            orderBy("username")
        );
        const snapshot = await getDocs(q);
    
        const patientData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        
        setPatientList(patientData);
    }, [userGroup]);
    
    useEffect(() => {
        callDb();
    }, [callDb]); // 의존성 배열을 비워서 한 번만 실행되도록 설정

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (activeMenu !== null && !event.target.closest('.evamanagefront_seldel_mobile')) {
                setActiveMenu(null);
            }
        };
    
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [activeMenu]);

    const handleClick = (e, index) => {
        const { top, left, height } = e.target.getBoundingClientRect();
    
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const menuWidthInPixels = 5 * rootFontSize;
        const menuHeightInPixels = 5.5 * rootFontSize;
    
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        const windowHeight = window.innerHeight;
    
        let topPosition = top + scrollTop + height - 20;
        let leftPosition = left + scrollLeft - menuWidthInPixels;
    
        // 메뉴가 화면 아래로 넘어가는 경우 위로 표시
        if (topPosition + menuHeightInPixels > scrollTop + windowHeight) {
            topPosition = top + scrollTop - menuHeightInPixels + 20;
        }

        // 메뉴가 화면 왼쪽으로 넘어가는 경우 오른쪽에 표시
        if (leftPosition < 0) {
            leftPosition = left + scrollLeft;
        }
    
        setMenuPosition({
            top: topPosition,
            left: leftPosition,
        });
        setActiveMenu(activeMenu === index ? null : index);
    };
    
    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva">
                        <div>
                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:"4.5rem"}}>
                                <div className="renew_content_title">담당자 연결</div>
                                <div style={{display:'flex',fontSize:"1.5rem",fontWeight:600,color:'#565656'}}>
                                    <div style={{color:'#7962F6',marginRight:7}}>{todayText}</div>기준
                                </div>
                            </div>
                            <div style={{display:'flex',borderBottom:'#E8E8E8 1px solid',color:'#9F9F9F',fontSize:"1rem",
                                        fontWeight:500,paddingBottom:"0.62rem",width:'100%'}}>
                                <div className="evamanagefront_name">이름</div>
                                <div className="evamanagefront_birth">생년월일</div>
                                <div className="evamanagefront_phone">전화번호(끝 4자리)</div>
                            </div>
                            {patientList
                            .map((option, index) => (
                                <div key={option.id} style={{display:'flex',color:'#373737',fontSize:"1.125rem",fontWeight:500,
                                    height:"3.5rem",borderBottom:'#E8E8E8 1px solid',background:index%2 === 0 && '#FAFAFA',
                                    alignItems:'center',justifyContent:'space-between'}}>
                                    <div style={{display:'flex'}}>
                                        <div className="evamanagefront_name">{option.username}</div>
                                        <div className="evamanagefront_birth">{option.userbirth}</div>
                                        <div className="evamanagefront_phone">{option.userphone.slice(-4)}</div>
                                    </div>
                                    <div className="evamanagefront_seldel">
                                        <button className="renew_evamngfront"
                                                onClick={()=>{
                                                    navigate("/evaManageBack", {
                                                        state: {
                                                            userinfo: option
                                                        },
                                                    });
                                                }}
                                        >선택</button>
                                        <button className="renew_evamngfront"
                                                onClick={()=>{
                                                    //const cateval = event.target.cateval.value;
                                                    if(userAuth === 1 || userAuth === 2 || userAuth === 7){
                                                        Swal.fire({
                                                            icon: "question",
                                                            title: "환자 삭제",
                                                            text: "환자 삭제를 하시겠습니까?",
                                                            showCancelButton: true,
                                                            confirmButtonText: "삭제",
                                                            cancelButtonText: "취소",
                                                        }).then(async (res) => {
                                                            if (res.isConfirmed) {
                                                                await deleteDoc(doc(db, "evapatient", option.id))

                                                                Swal.fire({
                                                                    icon: "success",
                                                                    title: "환자 삭제 완료",
                                                                    text: "환자 삭제가 완료되었습니다",
                                                                    //showCancelButton: true,
                                                                    confirmButtonText: "확인",
                                                                    //cancelButtonText: "취소",
                                                                }).then((res) => {
                                                                    /* Read more about isConfirmed, isDenied below */
                                                                    if (res.isConfirmed) {
                                                                        //확인 요청 처리
                                                                        callDb();
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                }}
                                        >삭제</button>
                                    </div>
                                    <div className="evamanagefront_seldel_mobile">
                                        <img src={More} alt='더보기' style={{width:"2.5rem",height:"2.5rem",cursor:'pointer'}} 
                                            onClick={(e) => handleClick(e, index)}
                                        />
                                    </div>
                                    {activeMenu === index && (
                                        <div style={{
                                            position: 'absolute',
                                            top: `${menuPosition.top}px`,
                                            left: `${menuPosition.left}px`,
                                            width: '5rem',
                                            height: '5.5rem',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            zIndex: 100, // 다른 요소들 위에 표시되도록 함
                                            boxShadow: "9px 4px 42px 4px rgba(0,0,0,0.04)",
                                        }}>
                                            <div className="evamanagefront_seldel_button"
                                                onClick={()=>{
                                                    navigate("/evaManageBack", {
                                                        state: {
                                                            userinfo: option
                                                        },
                                                    });
                                                }}
                                            >선택</div>
                                            <div style={{width:'5rem',height:1,background:"#F0F0F0"}} />
                                            <div className="evamanagefront_seldel_button del"
                                                onClick={()=>{
                                                    //const cateval = event.target.cateval.value;
                                                    if(userAuth === 1 || userAuth === 2 || userAuth === 7){
                                                        Swal.fire({
                                                            icon: "question",
                                                            title: "환자 삭제",
                                                            text: "환자 삭제를 하시겠습니까?",
                                                            showCancelButton: true,
                                                            confirmButtonText: "삭제",
                                                            cancelButtonText: "취소",
                                                        }).then(async (res) => {
                                                            if (res.isConfirmed) {
                                                                await deleteDoc(doc(db, "evapatient", option.id))

                                                                Swal.fire({
                                                                    icon: "success",
                                                                    title: "환자 삭제 완료",
                                                                    text: "환자 삭제가 완료되었습니다",
                                                                    //showCancelButton: true,
                                                                    confirmButtonText: "확인",
                                                                    //cancelButtonText: "취소",
                                                                }).then((res) => {
                                                                    /* Read more about isConfirmed, isDenied below */
                                                                    if (res.isConfirmed) {
                                                                        //확인 요청 처리
                                                                        callDb();
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                }}
                                            >삭제</div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaManageFront;