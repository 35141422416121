import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, setDoc, doc, getDocs, getDoc, query, where, updateDoc } from "firebase/firestore";
import { db, auth } from '../firebase_config.js';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import Checkbox from '../Checkbox.js';
import Swal from "sweetalert2";


function NoticeWrite(props) {
  const navigate = useNavigate();
  const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
  const contentData = state.contentdata;
  const noticeName = state.noticename;
  const noticeCode = state.noticecode;

  let initialTitle = "";
  let initialContent = "";
  let initialSecret = false;
  let initialIstop = false;
  let initialFiles = [];

  if (contentData !== "NEW") {
    initialTitle = contentData.title;
    initialContent = contentData.content;
    initialSecret = contentData.secret;
    initialIstop = contentData.istop;

    // 파일 정보 추출
    for (let i = 1; i <= 5; i++) {
      if (contentData[`attachedfilename${i}`]) {
          initialFiles.push({
              name: contentData[`attachedfilename${i}`],
              url: contentData[`attachedfile${i}`],
              isExisting: true  // 기존 파일임을 표시
          });
      }
    }
  }

  const [title, setTitle] = useState(initialTitle !== "" ? initialTitle : "");
  const [content, setContent] = useState(initialContent !== "" ? initialContent : "");
  const [secret, setSecret] = useState(initialSecret !== false ? initialSecret : false);
  const [istop, setIstop] = useState(initialIstop !== false ? initialIstop : false);
  const [selectedFiles, setSelectedFiles] = useState(initialFiles);

  const handleInputChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const save = async () => {
    if(title !== "" && content !== ""){
      if(contentData === "NEW"){
        try{   
          const storage = getStorage();  
          const now = new Date();
          // "users" 테이블에서 작성자 정보 가져오기 
          const userId = auth.currentUser.email;
          const userDocRef = doc(db, "users", userId);
          const userSnapshot = await getDoc(userDocRef);

          // "code" 필드가 noticeCode인 데이터의 개수 가져오기
          const noticeQuery = query(collection(db, "bbsdata"), where("code", "==", noticeCode));
          const noticeSnapshot = await getDocs(noticeQuery);
          const noticeCount = noticeSnapshot.size+1;

          const dataId = noticeCode+noticeCount
          const docRef = doc(db, "bbsdata", dataId);

          // 파일 업로드 및 URL 가져오기
          const fileUrls = await Promise.all(selectedFiles.map(async (file, index) => {
            const fileRef = storageRef(storage, `notice/${dataId+"_"+index}`);
            await uploadBytes(fileRef, file);
            return getDownloadURL(fileRef);
          }));

          // 데이터베이스에 저장할 객체 생성
          const bbsData = {
            code: noticeCode,
            seq: noticeCount,
            userid: userSnapshot.data().userId,
            username: userSnapshot.data().userName,
            title: title,
            content: content,
            regdate: now,
            secret: secret,
            reply: "N",
            replycount: 0,
            replyseq: 0,
            replydest: "",
            count: 0,
            delyn: "N",
            istop: istop,
            group: props.userGroup,
            attachedfile1: "",
            attachedfile2: "",
            attachedfile3: "",
            attachedfile4: "",
            attachedfile5: "",
            attachedfilename1: "",
            attachedfilename2: "",
            attachedfilename3: "",
            attachedfilename4: "",
            attachedfilename5: "",
          };

          // 첨부 파일 URL 추가
          fileUrls.forEach((url, index) => {
            bbsData[`attachedfile${index + 1}`] = url;
          });

          // 첨부 파일 이름 추가
          selectedFiles.forEach((file, index) => {
              bbsData[`attachedfilename${index + 1}`] = file.name;
          });

          // 데이터베이스에 저장
          try { 
              await setDoc(docRef, bbsData).then(
                Swal.fire({
                  icon: "success",
                  title: "게시물 등록 완료",
                  text: "게시물 등록완료 되었습니다",
                  //showCancelButton: true,
                  confirmButtonText: "확인",
                  //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        setTitle("");
                        setContent("");
                        setSecret(false);
                        navigate("/notice",{
                          state: {
                            id: noticeName,
                            code: noticeCode
                          },
                        })
                    }
                })
              );
          } catch (error) {
              console.error("Error adding document: ", error);
              showAlert("error", "업로드 실패", "다시 시도해주세요.");
          }
        } catch(error) {
            console.log("에러메세지:", error.message);
        }
      }else{
        const storage = getStorage();
        const docRef = doc(db, "bbsdata", contentData.id);
        const updatedData = {
            title: title,
            content: content,
            secret: secret,
            istop: istop,
        };

        // 새로 추가된 파일 업로드
        const newFiles = selectedFiles.filter(file => !file.isExisting);
        const uploadedFileUrls = await Promise.all(newFiles.map(async (file, index) => {
            const fileRef = storageRef(storage, `notice/${contentData.id}_${index}_${new Date().getTime()}`);
            await uploadBytes(fileRef, file);
            return getDownloadURL(fileRef);
        }));

        // 기존 파일과 새 파일 정보 합치기
        const allFiles = [
            ...selectedFiles.filter(file => file.isExisting),
            ...newFiles.map((file, index) => ({
                name: file.name,
                url: uploadedFileUrls[index],
                isExisting: false
            }))
        ];

        // 파일 정보 업데이트
        for (let i = 1; i <= 5; i++) {
            if (allFiles[i - 1]) {
                updatedData[`attachedfile${i}`] = allFiles[i - 1].url;
                updatedData[`attachedfilename${i}`] = allFiles[i - 1].name;
            } else {
                updatedData[`attachedfile${i}`] = "";
                updatedData[`attachedfilename${i}`] = "";
            }
        }

        try{    
          // 사용하지 않는 기존 파일 삭제 (옵션)
          const unusedFiles = contentData.attachedfile1 ? [1, 2, 3, 4, 5].map(i => contentData[`attachedfile${i}`]).filter(url => url && !allFiles.some(file => file.url === url)) : [];
          for (const url of unusedFiles) {
              const fileRef = storageRef(storage, url);
              await deleteObject(fileRef);
          }
          await updateDoc(docRef, updatedData).then(
            Swal.fire({
              icon: "success",
              title: "게시물 수정 완료",
              text: "게시물 수정완료 되었습니다",
              //showCancelButton: true,
              confirmButtonText: "확인",
              //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                    navigate("/notice",{
                      state: {
                        id: noticeName,
                        code: noticeCode
                      },
                    })
                }
            })
          );
        } catch(error) {
            console.log("에러메세지:", error.message);
        }
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "누락된 제목/내용",
        text: "제목과 내용을 입력하세요",
        //showCancelButton: true,
        confirmButtonText: "확인",
        //cancelButtonText: "취소",
      }).then((res) => {
          /* Read more about isConfirmed, isDenied below */
          if (res.isConfirmed) {
              //확인 요청 처리
          }
      });
    }
  };

  const goNotice = () => {
    Swal.fire({
      icon: "warning",
      title: "게시판으로 돌아가시겠습니까?",
      text: "작성하신 내용은 저장되지 않습니다.",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((res) => {
        /* Read more about isConfirmed, isDenied below */
        if (res.isConfirmed) {
            //확인 요청 처리
            navigate("/notice",{
              state: {
                id: noticeName,
                code: noticeCode
              },
            })
        }
    });
  }

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const validNewFiles = newFiles.filter(file => file.size <= 10 * 1024 * 1024); // 10MB 이하

    // 기존 파일과 새 파일을 합침
    const allFiles = [...selectedFiles, ...validNewFiles];

    if (allFiles.length > 5) {
        showAlert("error", "파일 갯수 한도초과", "최대 5개의 파일만 선택할 수 있습니다.");
        return;
    }

    if (validNewFiles.length < newFiles.length) {
        showAlert("warning", "파일 용량 한도초과", "10MB를 초과하는 파일은 제외되었습니다.");
    }

    // 최대 5개까지만 유지
    const finalFiles = allFiles.slice(0, 5);

    setSelectedFiles(finalFiles);
  };

  const handleFileDelete = (indexToDelete) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToDelete));
  };

  const showAlert = (errDiv, errTitle, errMsg) => {
    Swal.fire({
        icon: errDiv,
        title: errTitle,
        text: errMsg,
        confirmButtonText: "확인",
    }).then((res) => {
        if (res.isConfirmed) {
            // 확인 요청 처리
        }
    });
  };

  return (
    <div className="renew_background" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <div className="renew_content_background notice">
        <div>
          <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>{noticeName} 등록</div>
          <div className="renew_qna_read_title" style={{fontSize:"1rem",marginBottom:"0.5rem"}}>제목</div>
          <input style={{padding:"1rem",borderRadius:"0.5rem",background:'#F4F4F0',color:'#373737',fontSize:"1rem",
                        fontWeight:500,marginBottom:"3.5rem",width:"100%"}}
              id="_title" 
              type="text" 
              placeholder='제목을 입력하세요.' 
              value={title}
              onChange={handleInputChange}
          />
          <div className="renew_qna_read_title" style={{fontSize:"1rem",marginBottom:"0.5rem"}}>내용</div>
          <textarea 
            style={{
                minHeight:300,
                padding:"1rem",
                borderRadius:"0.5rem",
                background:'#F4F4F0',
                color:'#373737',
                fontSize:"1rem",
                fontWeight:500,
                marginBottom:"1.6875rem",
                width:"100%",
                resize: 'none',
            }} 
            placeholder='내용을 입력하세요.' 
            value={content}
            onChange={handleContentChange}
          />
          <div className="renew_qna_read_title" style={{fontSize:"1rem",marginBottom:"0.5rem"}}>첨부</div>
          <div style={{display:'flex',alignItems:'center',marginBottom:"1rem"}}>
            <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{display: 'none'}}
                id="fileInput"
            />
            <label htmlFor="fileInput" style={{
                    marginRight: "1rem",
                    padding: '0.5rem 1rem',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer'
            }}>
            파일선택
            </label>
            <div>※ 첨부파일은 최대 5개, 10MB를 넘을 수 없습니다.</div>
          </div>
          {selectedFiles.length > 0 && (
            <div style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                padding: "1rem",
                backgroundColor: "lightgrey",
                borderRadius: "4px",
                width:"95%"
            }}>
              <ul style={{ listStyleType: 'none', padding: 0, display: 'block' }}>
                {selectedFiles.map((file, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                  <span>{file.name}</span>
                  {file.isExisting ? (
                      <span style={{ marginLeft: '0.5rem', color: 'blue' }}>(기존 파일)</span>
                  ) : null}
                  <button 
                      onClick={() => handleFileDelete(index)}
                      style={{
                          marginLeft:"1rem",
                          backgroundColor: '#ff4d4d',
                          color: 'white',
                          border: 'none',
                          padding: '0.25rem 0.5rem',
                          borderRadius: '4px',
                          cursor: 'pointer'
                      }}
                  >
                  삭제
                  </button>
                </li>
                ))}
              </ul>
            </div>
          )}
          <div style={{display:'flex',color:'#909090',fontSize:"0.9375rem",fontWeight:500,alignItems:'center'}}>
            <Checkbox checked={secret} onChange={setSecret} />
            <div style={{marginLeft:"0.5rem",marginRight:"0.625rem"}}>비밀글(작성자만 볼 수 있습니다)</div>
            {(props.userAuth === 7 || props.userAuth === 1) && 
              <>
                <Checkbox checked={istop} onChange={setIstop} />
                <div style={{marginLeft:"0.5rem"}}>공지여부</div>
              </>
            }
          </div>
        </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:"5.625rem"}}>
          <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF',width:"8.25rem"}}
                  onClick={()=>goNotice()}
          >취소</button>
          <button className="renew_evasignup" style={{width:"8.25rem",marginLeft:"1rem"}}
                  onClick={()=>save()}
          >작성 완료</button>
        </div>
      </div>
    </div>
  )
}

export default NoticeWrite;